import './normalize.css';
import './index.css';
import './LoginForm.css';
import './RegistrationForm.css';
import './Query.css';
import './ResetPasswordForm.css';
import './Admin.css';

const MyApp = ({ Component, pageProps }) => {
   return <Component {...pageProps} />
}

export default MyApp